<template>
  <div>
    <NavBar class="fixed top-0 left-0 right-0"></NavBar>

    <secondary-navbar
      title="Payment Failure"
      :showbroker="false"
      :showaction="false"
      :showportfolio="true"
    />

    <div class="w-full mt-6 grid grid-cols-10 hidden sm:grid" id="page-login">
      <div class="col-span-2 sm:block 0 background-f4">
        <left-side-strip :showBrokerButton="true" :showPie="false" />
      </div>

      <div class="col-span-8 m-20">
        <h1 class="text-4xl oeno_portfolio_title pb-10">Sorry but we had an issue with the payment.</h1>

        <div class="flex justify-center">
          <button
            type="submit"
            @click="completeForm()"
            class="w-72 h-10 oeno_button_black mt-10 align-center"
            >{{ updateStatus ? "Processing...." : "Continue " }}</button
          >
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/layout/navbar.vue";
import SecondaryNavbar from "@/components/layout/SecondaryNavbar.vue";
import Footer from "@/components/layout/footer.vue";
import LeftSideStrip from "@/components/pageparts/LeftSideStrip.vue";

export default {
  components: {
    NavBar,
    Footer,
    LeftSideStrip,
    SecondaryNavbar,
  },
  data() {
    return {
      deliveryAddress: null,
      updateStatus: false,
      orderData: null,
    };
  },
  created: function () {
    this.$store.dispatch("storeOrderData", {});
  },
  methods: {
    completeForm() {
      this.$router.push({
        name: "page-portfolio",
      });
    },
  },
};
</script>
